<template>
  <div class="box" @scroll="scrollSetData">
    <!-- 头部 -->
    <headers></headers>
    <!-- 轮播图 -->
    <el-carousel :interval="3000">
      <el-carousel-item v-for="item in bannerImgList" :key="item.id"> 
        <img :src="item.url" class="imagess">
      </el-carousel-item>
    </el-carousel>
    <!-- 导航 -->
    <div class="content">
      <div class="content_01">
        <span v-for="item in dataList" @click="tebBtn(item.id)" :class="['p1', { 'p2': item.id == id }]"
          :key="item.id">{{
              item.name
          }}</span>
      </div>
    </div>
    <div class="contentData">
      <div style="margin: 20px;" v-for="item in navData" :key="item.id">
        <div class="elCard">
          <div class="camera">
            <div class="camera_img">
              <img :src="item.cameraUrl" alt="">
            </div>
            <div class="camera_text">
              <h2>{{ item.cameraName }}</h2>
              <p>{{ item.cameraTitle }}</p>
            </div>
          </div>
          <div class="imgList">
            <div class="imgList_top">
              <el-card class="card">
                <h2>{{ item.mobile_top }}</h2>
                <li v-if="item.liData != '' && item.list2Data == null">{{ item.liData }}</li>
                <p style="display: flex;" v-if="item.list2Data != null">
                  <li>{{ item.list2Data.list_1 }}</li>
                  <li style="margin-left: 8rem;">{{ item.list2Data.list_2 }}</li>
                </p>
              </el-card>
              <div class="card_bottom" :class="{ card_bottom_1: item.liData != '' || item.list2Data != null }">
                <!-- 技術優勢 -->
                <div class="box" v-for="e in item.mobileList" :key="e.id">
                  <p v-if="item.liData == '' && item.list2Data == null">{{ e.name }}</p>
                  <img :class="{ igs: item.isHeight }" :src="e.url" alt="">
                </div>
              </div>
            </div>
            <div class="imgList_bottom" :class="{ imgList_bottom_1: item.mobile_title == '笔记本电脑' }">
              <el-card class="card">
                <h2>{{ item.mobile_bottom }}</h2>
                <p>{{ item.mobile_title }}</p>
              </el-card>
              <div class="box_1">
                <div class="box_01" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists == null">
                  <img class="img_2" :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <div class="box_02" v-for="obj in item.imgLists" :key="obj.id" v-if="item.imgLists != null">
                  <img :src="obj.url" alt="" v-if="item.imgs == ''">
                </div>
                <img :src="item.imgs" img_11alt=""
                  :class="[{ img_11: item.imgs != '' }, { img_22: item.mobile_title == '笔记本电脑' || item.Veh }]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p element-loading-text="ロード中，お待ちください..." v-loading="loading" style="height: 5.5rem;"></p>
      <p v-if="loading" class="loading"></p>
      <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);">データロード完了</p>
      <!-- <p class="data_p" v-if="id!=5">没有数据</p> -->
      <!-- <div v-else style="width: 100%;height:300px">111</div> -->
    </div>
    <!-- 底部 -->
    <foot @submitData="submitData"></foot>

  </div>
  <div class="_div">
    <!--  小屏-->
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item  v-for="item in bannerImgList" :key="item.id">
        <img :src="item.url" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <el-scrollbar class="content_top">
      <p v-for="item in dataList_move" @click="tebBtn(item.id)" :key="item.id" :class="{ p1: item.id == id }">{{
          item.name
      }}
      </p>

    </el-scrollbar>
    
    <div class="content_move" v-for="item in navData" :key="item.id">
      <div class="box_top">
        {{ item.name }}
        <div class="box_top_1"></div>
      </div>
      <div class="box_bottom">
        <div class="img_left">
          <img :src="item.cameraUrl_move" alt="">
          <h2>製品説明</h2>
          <p>{{ item.cameraTitle }}</p>
        </div>
        <div class="img_right">
          <h2>技術優勢</h2>
          <p>{{ item.liData }}</p>
          <img v-for="e in item.mobileList_move" :key="e.id" :src="e.url" alt="" v-if="item.mobileList_move != null"
            style="width: 35%;">
          <img :src="item.mobileList[0].url" alt="" v-if="item.mobileList_move == null" style="width: 50rem;">
          <h2 style="margin-top: 7rem;">利用場面</h2>
          <p>{{ item.mobile_title }}</p>
          <img :src="item.imgLists[0].url" alt="" v-if="!item.isIMG" style="width: auto;height: 22rem;"
            :class="{ che: item.Veh == true }">
          <!-- <div class="IMG_box" v-if="item.isIMG">
            <img class="isIMGData" :src="i.url" alt=""  v-for="i in item.imgLists" :key="i.id"/>
          </div>   -->
          <img style="width: auto;height:17rem;margin-top: 3rem;" :src="i.url" alt="" v-for="i in item.imgLists"
            :key="i.id" v-if="item.isIMG" />
        </div>
      </div>
    </div>
     <p element-loading-text="ロード中，お待ちください..." v-loading="loading" style="height: 13rem;"></p>
      <p v-if="loading" class="loading"></p>
      <p class="data_p" v-if="noMore" style="color: rgb(64,158,255);margin: 5 rem 0;" >データロード完了</p>
    <!-- 底部 -->
    <move_foot @submitData="submitData"></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import { reactive, toRefs, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import move_header from "../move_components/move_header.vue";
import move_foot from "../move_components/move_foot.vue";
export default {
  components: { foot, headers, move_foot, move_header },
  setup() {
    const noMore = ref(false)
    const route = useRouter();
    const isRefreshBool = ref(true)
    const el_card = ref()
    const state = reactive({
      loading: false,
      id: localStorage.getItem('id') || 5,
      bannerImgList:[
        {id:1,url:require('@/assets/homePage/ja-product1.png')},
        {id:2,url:require('@/assets/homePage/ja-product2.png')},
        {id:3,url:require('@/assets/homePage/ja-product3.png')},
        {id:4,url:require('@/assets/homePage/ja-product4.png')},
        {id:5,url:require('@/assets/homePage/ja-product5.png')},
      ],
      dataList_move: [
        { id: 5, name: 'モバイル端末', url: '', content: '' },
        { id: 4, name: 'ノートパソコン', url: '', content: '' },
        { id: 3, name: 'アイオット', url: '', content: '' },
        { id: 2, name: '車載と医療製品', url: '', content: '' },
        { id: 1, name: 'LED', url: '', content: '' },
      ],
      dataList: [
        { id: 1, name: 'LED', url: '', content: '' },
        { id: 2, name: '車載と医療製品', url: '', content: '' },
        { id: 3, name: 'アイオット', url: '', content: '' },
        { id: 4, name: 'ノートパソコン', url: '', content: '' },
        { id: 5, name: 'モバイル端末', url: '', content: '' },
      ],
      navData: [],

      // LED数据
      LEDList: [{
        id: 1, isIMG: true, name: 'LEDモジュール', liData: '微細なLocal Dimmingを可能にします；超高コントラスト、より見やすいカラーコントラストも実現します。', cameraUrl_move: require('@/assets/product_center/LED/move_1.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/LEDモジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォン、ノートパソコン、AR/VRメガネなど。', mobile_bottom: '利用場面', cameraTitle: 'LEDモジュールは、SMDやCOBなどのプロセスでPCBやガラス基板にLEDを実装し、バックライトなどの用途に実現します。本製品はテレビ、ノートパソコン、タブレット、自動車、携帯電話、VR/ARグラスなどの場面で使用されています。',
        list2Data: { list_1: ' 微細なLocal Dimmingを可能にします；', list_2: '超高コントラスト、より見やすいカラーコントラストも実現します。' },
        mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },
        { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/LED/3.png') },
        { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/LED/4.png') }],
        mobileList_move:
          [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/LED/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/LED/2.png') },],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 3, url: require('@/assets/product_center/LED/眼镜.png') },
        ]

      },
      {
        id: 2, isIMG: true, name: 'ロゴ表示用LEDモジュール', liData: 'FPC+LGFモジュール+LED+コネクタ; LED3.0*1.0*0.4mm、RGB、20mAクロスフロードライブ。', cameraUrl_move: require('@/assets/product_center/LED/move_2.png'), isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/LED/ロゴ表示用LEDモジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'タブレット、ノートパソコン、スマートフォンなど。', mobile_bottom: '利用場面', cameraTitle: 'COG01Tは、GAMING PHONEで使用され、2つのLEDからの光をLGF（ライトガイドフィルム）で面光源に導き、発光させることを目的としています。',
        list2Data: { list_1: 'FPC+LGFモジュール+LED+コネクタ', list_2: 'LED3.0*1.0*0.4mm、RGB、20mAクロスフロードライブ。' },
        mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
        { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/LED/7.png') },
        { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/LED/8.png') }],
        mobileList_move: [
          { id: 1, name: '背景虚化', url: require('@/assets/product_center/LED/5.png') },
          { id: 2, name: '超大光圈', url: require('@/assets/product_center/LED/6.png') },
        ],
        imgLists: [
          { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
          { id: 2, url: require('@/assets/product_center/LED/电脑.png') },
          { id: 2, url: require('@/assets/product_center/LED/平板.png') },
        ]
      }],
      // NB数据
      NBList: [
        {
          id: 1, name: '超狭額・薄型ノートパソコンカメラ', cameraUrl_move: require('@/assets/product_center/NB/move_1.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: ' 解像度FHD（2M、1080P）RGBセンサー、OV02CRGBレンズPowertip、4P、単三レンズ。', cameraUrl: require('@/assets/product_center/NB/超狭額薄型ノートパソコンカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'ノートパソコン', mobile_bottom: '利用場面', cameraTitle: '薄型・軽量ノートパソコンに採用され、モジュールはCOBプロセスで製造され、有効画素数200万、高さ2.58mm、幅3mmという薄さも実現しています。',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/1.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
        {
          id: 2, name: 'ノートパソコンデュアルカメラ', cameraUrl_move: require('@/assets/product_center/NB/move_2.png'), imgs: require('@/assets/product_center/NB/电脑的副本2.png'), liData: 'AI生体認証/アンチスヌーピングに対応します。', cameraUrl: require('@/assets/product_center/NB/ノートパソコンデュアルカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'ノートパソコン', mobile_bottom: '利用場面', cameraTitle: '解像度2M（FHD）＋1M（HD）、RGBセンサーOV02CU COB、RGBレンズPT 42091、IRセンサーHM1092U CSP、IRレンズPowertip 3Pレンズ。',
          mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/NB/3.png') },
          { id: 2, name: '前摄大视野', url: require('@/assets/product_center/NB/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
            { id: 2, url: require('@/assets/product_center/mobile/图2.png') },
            { id: 3, url: require('@/assets/product_center/mobile/图3.png') },
          ],
        },
      ],
      // 车载
      vehicleList: [
        {
          id: 1, name: "ピロスクリーンカメラモジュール-4K（32M）", cameraUrl_move: require('@/assets/product_center/vehicle/move_1.png'), isHeight: false, Veh: true, imgs: '', liData: '360度アラウンドビュー（AVM）フォーサイトテクノロジー、32M後席ピロティ。', cameraUrl: require('@/assets/product_center/vehicle/ピロスクリーンカメラモジュール-4K（32M）.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'BYDの「漢」シリーズと「元」シリーズ', mobile_bottom: '利用場面', cameraTitle: '本製品はBYDの「漢」シリーズと「元」シリーズに採用されており、「漢」シリーズはフォーサイトテクノロジー32Mリアシートピローソリューション、「元」シリーズはサラウンドビュー映像ソリューションです。',
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车1.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车2.png') },
          ],

        },
        {
          id: 2, name: 'DMS/DVR/360度ビュー/OMS', cameraUrl_move: require('@/assets/product_center/vehicle/move_2.png'), isHeight: false, Veh: true, imgs: '', liData: 'より鮮明な記録/より広い記録範囲/簡単な保存/インテリジェント自動検出/より自然な画像。', cameraUrl: require('@/assets/product_center/vehicle/DMSDVR360度ビュOMS.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '一汽、長安、東風-中汽創智。', mobile_bottom: '利用場面', cameraTitle: '本製品は一汽、長安、東風-中汽創智などの車種に使用され、より鮮明な画面、広い範囲、便利な収納など様々なメリットがあります。',
          list2Data: null,
          isIMG: false,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/4.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/vehicle/车3.png') },
            { id: 2, url: require('@/assets/product_center/vehicle/车4.png') },
          ],
        }
      ],
      // 智能物联网
      AIoTList: [
        {
          id: 1, name: '外付け4Kビデオ会議用カメラ', cameraUrl_move: require('@/assets/product_center/AIoT/move_1.png'), isHeight: false, imgs: '', liData: 'USB3.0転送プロトコル、4K映像出力、Windows Hello顔認証ログイン、Teams、Lyncなどのビデオ会議の認証に対応します。', cameraUrl: require('@/assets/product_center/AIoT/外付け4Kビデオ会議用カメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'ノートパソコン、デスクトップパソコン', mobile_bottom: '利用場面', cameraTitle: 'BW831Cは、800万画素の外付けカメラです。写真やビデオの機能を持ち、高解像度のビデオ会議、HDウェブキャスティングなどのために設計されています。',
          list2Data: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/1.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/2.png') }],
          mobileList_move: null,
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/电脑的副本.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/电脑.png') },
          ],

        },
        {
          id: 2, name: 'IPCカメラ', cameraUrl_move: require('@/assets/product_center/AIoT/move_2.png'), isHeight: false, imgs: '', liData: '3Pレンズ、GC2063センサー、GK7205低消費電力、高性能SOC。', cameraUrl: require('@/assets/product_center/AIoT/IPCカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '3Pレンズ、GC2063センサー、GK7205低消費電力、高性能SOC。', mobile_bottom: '利用場面', cameraTitle: '3Pレンズ、GC2063センサー、GK7205低消費電力、高性能SOCを搭載、1080P HDRビデオモードに対応します。',
          list2Data: null,
          mobileList_move: null,
          mobileList: [
            { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/AIoT/3.png') },
            { id: 2, name: '前摄大视野', url: require('@/assets/product_center/AIoT/4.png') }],
          imgLists: [
            { id: 1, url: require('@/assets/product_center/AIoT/5.png') },
            { id: 2, url: require('@/assets/product_center/AIoT/6.png') },
          ],
        }
      ],
      // 超大广角这两个
      UltraList:[
          {
              id: 1, isHeight: false, name: '超大型広角モジュール', imgs: '', liData: 'フロントカメラは高画質と大視野を実現、リアカメラは光学ズームと光量アップを補助', cameraUrl_move: require('@/assets/product_center/mobile/超大广角/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/超大广角/超大型広角モジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '携帯電話、スマートフォン撮影', mobile_bottom: '利用場面', cameraTitle: '1600万画素の広角、レンズの視野角（FOV）を大きくして写真の視野を広げ、光の取り込みを高め、また多倍ズームなどの機能を搭載しています。',
              list2Data: null,
              mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
              { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
              { id: 3, name: '后摄辅助光学变焦', url: require('@/assets/product_center/mobile/超大广角/超大广角3.png') },
              { id: 4, name: '后摄辅助增加光量', url: require('@/assets/product_center/mobile/超大广角/超大广角4.png') }],
              mobileList_move: [
                { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/超大广角/超大广角1.png') },
                { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/超大广角/超大广角2.png') },
              ],
              imgLists: [
                { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
              ]
            },
            {
              id: 2, isHeight: false, name: 'デュアルカメラモジュール', imgs: '', liData: '背景ボケ、大口径、夜景のを鮮明に、超高ダイナミックレンジのキャプチャ', cameraUrl_move: require('@/assets/product_center/mobile/双摄模组/move_1.png'), cameraUrl: require('@/assets/product_center/mobile/双摄模组/デュアルカメラモジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '携帯電話、スマートフォン撮影', mobile_bottom: '利用場面', cameraTitle: '4800万画素のフォーカス式カメラと500万画素の固定焦点式カメラを金属製のブラケットで組み合わせ、複合カメラと構成します。これにより、光の取り込み量を増やすこと、また背景ボケやハイダイナミックレンジ撮影も可能です。',
              list2Data: null,
              mobileList: [{ id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
              { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
              { id: 3, name: '夜间拍摄效果清晰', url: require('@/assets/product_center/mobile/双摄模组/双摄模组3.png') },
              { id: 4, name: '超高动态范围捕捉', url: require('@/assets/product_center/mobile/双摄模组/双摄模组4.png') }],
              mobileList_move: [
                { id: 1, name: '背景虚化', url: require('@/assets/product_center/mobile/双摄模组/双摄模组1.png') },
                { id: 2, name: '超大光圈', url: require('@/assets/product_center/mobile/双摄模组/双摄模组2.png') },
              ],
              imgLists: [
                { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
              ]
            }
      ]
    })
    let methods = {
      tebBtn(id) {
        state.id = id
        noMore.value = false
        localStorage.setItem('id', id)
        if (state.id == 1) {
          state.navData = state.LEDList
        } else if (state.id == 2) {
          state.navData = state.vehicleList
        } else if (state.id == 3) {
          state.navData = state.AIoTList
        } else if (state.id == 4) {
          state.navData = state.NBList
        } else if (state.id == 5) {
          state.navData = state.UltraList
        }
        setTimeout(() => {
         methods.alternate()
        })

      },
      submitData(id) {
        methods.tebBtn(id)
        // console.log('id',id);
      },
      scrollSetData() {
        window.onscroll = () => {
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          //滚动条到底部的条件(距底部20px时触发加载)
          let Date = 1000
          if (
            scrollTop + windowHeight >= scrollHeight - 400
          ) {
            let id = localStorage.getItem('id') || 5
            let num = state.navData.length
            if (id == 5 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 3, name: 'OIS光学式手ブレ補正モジュール', cameraUrl_move: require('@/assets/product_center/mobile/OIS光学防抖模组/move_1.png'), isHeight: false, imgs: '', liData: '撮影した画像のアルゴリズムによる自動補正、手ブレによるブレを解消し、よりクリアでスムーズな撮影が可能です。', cameraUrl: require('@/assets/product_center/mobile/OIS光学防抖模组/OIS光学式手ブレ補正モジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '手持ち撮影、車載撮影、モバイルビデオ撮影。', mobile_bottom: '利用場面', cameraTitle: 'OIS光学式手ブレ補正カメラは、手ブレや外的要因による映像のブレを防ぎ、鮮明な画像を実現します。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/OIS光学防抖模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/OIS光学防抖模组/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ]
                  },
                                    {
                    id: 12, name: 'G+P大型センサーOISカメラ', cameraUrl_move: require('@/assets/product_center/mobile/G+P大底OIS摄像头/move_1.png'), isHeight: false, imgs: '', liData: '「G+P」レンズ技術は、非球面モールドガラス（1G+5P）によりレンズの光学性能を高め、迷光の除去や分散を抑えます。そして大型センサーとの組み合わせて、より緻密でリアルな写真表現を実現します。また、OIS手ブレ防止技術を使用し、極上の撮影体験を得られます。', cameraUrl: require('@/assets/product_center/mobile/G+P大底OIS摄像头/G+P大型センサーOISカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景と近景の撮影。', mobile_bottom: '利用場面', cameraTitle: 'G+P大型センサーOISカメラは、G+Pレンズ、大型センサーとOISモーターとの組み合わせで暗い場所や夜景でも高画質な映像が撮影できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/G+P大底OIS摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
 
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 5 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 5, name: '顕微鏡モジュール', cameraUrl_move: require('@/assets/product_center/mobile/显微镜模组/move_1.png'), isHeight: false, imgs: require('@/assets/product_center/mobile/显微镜模组/4.png'), liData: '3mmの超近接撮影が可能で、スマートフォンのアルゴリズムにより60倍の顕微鏡効果を実現し、ミクロの世界を探索することができます。', cameraUrl: require('@/assets/product_center/mobile/显微镜模组/顕微鏡モジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: '超マクロ撮影、顕微鏡で観察すること。', mobile_bottom: '利用場面', cameraTitle: '顕微鏡カメラモジュールは、スマートフォンのアルゴリズムを応用し、60倍の顕微鏡効果を実現することができます。',
                    list2Data: null,
                    mobileList_move: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/显微镜模组/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/显微镜模组/3.png') }],
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/显微镜模组/4.png') }
                    ],

                  },
                  {
                    id: 6, name: '内蔵フロントカメラ', cameraUrl_move: require('@/assets/product_center/mobile/一体化前置摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'ヘッドの直径が小さいため、スマートフォンの外カメラのサイズが小さくなり,画面の比率を改善し、全体の視覚体験を向上させます。', cameraUrl: require('@/assets/product_center/mobile/一体化前置摄像头/内蔵フロントカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'モバイルセルフィー', mobile_bottom: '利用場面', cameraTitle: '内蔵フロントカメラは、ヘッド径が小さく、レンズの解像度が高いため、スマートフォンのフロントカメラに使用されています。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/一体化前置摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/一体化前置摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 7 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 7, name: '高画素AFカメラ', cameraUrl_move: require('@/assets/product_center/mobile/高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'オートクイックフォーカス機能ができ、異なる距離の被写体を撮影する際に自動的にピントを合わせ、鮮明な映像を実現します。', cameraUrl: require('@/assets/product_center/mobile/高像素AF摄像头/高画素AFカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景と近景の撮影。', mobile_bottom: '利用場面', cameraTitle: '高画素AFモジュールは、メインカメラとして、オートフォーカス機能を使用し、異なる距離で高画素の画像を撮影できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 8, name: '200M超高画素AFカメラ', cameraUrl_move: require('@/assets/product_center/mobile/200M超高像素AF摄像头/move_1.png'), isHeight: false, imgs: '', liData: 'オートフォーカスをしながら最大200メガピクセル画素の超高精細画像を撮影でき、拡大しても細部まで鮮明に見えます。', cameraUrl: require('@/assets/product_center/mobile/200M超高像素AF摄像头/200M超高画素AFカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景と近景の撮影。', mobile_bottom: '利用場面', cameraTitle: '200M超高画素AFカメラは、業界最高画素数の200メガピクセルUHD写真を撮影できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/200M超高像素AF摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 9 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                  {
                    id: 9, name: 'ペリスコピック望遠カメラ', cameraUrl_move: require('@/assets/product_center/mobile/潜望式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: '画像の中心部と周辺部のシャープネスの差が少なく、ディテールのバランスが良いです。また、ペリスコープ構造設計により望遠レンズの高さを幅に変換して、スマートフォンをスリムなサイズになります。', cameraUrl: require('@/assets/product_center/mobile/潜望式长焦摄像头/ペリスコピック望遠カメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景の撮影。', mobile_bottom: '利用場面', cameraTitle: 'ペリスコピック望遠カメラは、光学5倍ズームを実現し、超遠景の被写体も高精細に撮影できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/潜望式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 10, name: 'テレスコピック望遠カメラ', cameraUrl_move: require('@/assets/product_center/mobile/伸缩式长焦摄像头/move_1.png'), isHeight: false, imgs: '', liData: '動作時はレンズが伸び、非動作時は引っ込むため、レンズの小型化ができ、スマートフォンのサイズに。また、伸縮自在の構造は大きなセンサーを搭載でき、撮影効果をアップします。', cameraUrl: require('@/assets/product_center/mobile/伸缩式长焦摄像头/テレスコピック望遠カメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景と近景の撮影。', mobile_bottom: '利用場面', cameraTitle: 'テレスコピック望遠カメラは、ポップアップレンズを伸縮させることでズームし、遠くの被写体も撮影できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/伸缩式长焦摄像头/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  }
                )
                isRefreshBool.value = true
                state.loading = false
              }, Date)
            } else if (id == 5 && num <= 11 && isRefreshBool.value) {

                            isRefreshBool.value = false
              state.loading = true

              setTimeout(() => {
                state.navData.push(
                  {
                    id: 13, name: '広角＋望遠＋高画素のトリプルカメラ', cameraUrl_move: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/move_1.png'), isHeight: true, imgs: '', liData: '広角＋望遠＋高画素のトリプルカメラ、3つのカメラを組み合わせることで、広角と望遠のカメラが高画素のメインカメラの撮影を補助し、よりクリアな写真表現ができるとともに、カメラの汎用性を高め、画質の露出を抑え、より良い写真を撮ります。', cameraUrl: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/広角＋望遠＋高画素のトリプルカメラ.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの様々な場面の撮影。', mobile_bottom: '利用場面', cameraTitle: '広角＋望遠＋高画素のトリプルカメラは、3つのカメラの組み合わせで、モバイル撮影の各利用場面をカバーします。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/广角+长焦+高像素三摄/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                  {
                    id: 14, name: 'DOF計算デュアルカメラモジュール', cameraUrl_move: require('@/assets/product_center/mobile/景深计算双摄模块/move_1.png'), isHeight: false, imgs: '', liData: 'DOF計算デュアルカメラモジュールは、主にDOFを計算して背景ボケとリフォーカスを実現する：絞り値を調整することで、写真のボケの度合いを変えたり、写真に写る被写体のDOF情報を呼び出したり、ピントを合わせる前に写真を撮る機能（リフォーカス機能）を有効にすることができます。', cameraUrl: require('@/assets/product_center/mobile/景深计算双摄模块/DOF計算デュアルカメラモジュール.png'), cameraName: '製品説明', mobile_top: '技術優勢', mobile_title: 'スマートフォンの遠景と近景の撮影、背景ボケ。', mobile_bottom: '利用場面', cameraTitle: 'DOF計算デュアルカメラモジュールは、DOFカメラとメインカメラの組み合わせによるリフォーカス機能を実現できます。',
                    list2Data: null,
                    mobileList: [{ id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/mobile/景深计算双摄模块/2.png') },
                    { id: 2, name: '前摄大视野', url: require('@/assets/product_center/mobile/景深计算双摄模块/3.png') }],
                    mobileList_move: null,
                    imgLists: [
                      { id: 1, url: require('@/assets/product_center/mobile/图1.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图2.png') },
                      { id: 1, url: require('@/assets/product_center/mobile/图3.png') },
                    ],
                  },
                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            } 
            if (id == 2 && num <= 3 && isRefreshBool.value) {
              isRefreshBool.value = false
              state.loading = true
              setTimeout(() => {
                state.navData.push(
                    {
                      id: 3, name: "DMS/OMS/DVR/ エンターテインメントカメラモジュール", cameraUrl_move: require('@/assets/product_center/vehicle/move_3.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360°サラウンドビュー 2M ADAS フロントビューオールインワン 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组1-ja.png'), cameraName: '製品分析', mobile_top: '技術なメリット', mobile_title: 'BYD, 広汽, Volvo, など。', mobile_bottom: '応用場面', cameraTitle: '本製品は、インテリジェントコックピットに搭載された自動運転支援装置, 1M/2M 360°サラウンドビューです。主な取引先はBYD、広汽、Volvoなど。',
                      list2Data: null,
                      isIMG: false,
                      mobileList: [
                        { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/5.png') },
                        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/6.png') }],
                      mobileList_move: null,
                      imgLists: [
                        { id: 1, url: require('@/assets/product_center/vehicle/车5.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/车6.png') },
                      ],

                    },
                    {
                      id: 4, name: 'DMS/OMS/DVR/ エンターテインメントカメラモジュール', cameraUrl_move: require('@/assets/product_center/vehicle/move_4.png'), isHeight: false, Veh: true, imgs: '', liData: '1M/2M 360°サラウンドビュー 2M ADAS フロントビューオールインワン 8M ADAS', cameraUrl: require('@/assets/product_center/vehicle/娱乐摄像模组2-ja.png'), cameraName: '製品分析', mobile_top: '技術なメリット', mobile_title: '長城、 XPeng Motor、NIO、Toyota など。', mobile_bottom: '応用場面', cameraTitle: '本製品は、スマートコックピット内の映像入力機器として、エンターテインメント機能をサポートする装置, リアルタイムビデオ技術に対応し、ゲームやテレビなどのエンターテインメント機能を備え、ビデオ通話にも対応可能です。主な取引先は長城、 XPeng Motor、NIO、Toyota など。',
                      list2Data: null,
                      isIMG: false,
                      mobileList: [
                        { id: 1, name: '前摄高清美艳', url: require('@/assets/product_center/vehicle/7.png') },
                        { id: 2, name: '前摄大视野', url: require('@/assets/product_center/vehicle/8.png') }],
                      mobileList_move: null,
                      imgLists: [
                        { id: 1, url: require('@/assets/product_center/vehicle/车7.png') },
                        { id: 2, url: require('@/assets/product_center/vehicle/车8.png') },
                      ],
                    },
                
                    
                    {
                       id: 5, isIMG: true, name: 'MCI-S全自動コロニー計数機', liData: '高密度・複雑な背景条件下でも正確かつ迅速なコロニー計数,すべてのデータ・画像・結果を自動保存、最小検出コロニーサイズ0.10mm', 
                       cameraUrl_move: require('@/assets/product_center/vehicle/菌落仪图片日文.png'), 
                       isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/vehicle/菌落仪图片日文.png'), 
                       cameraName: '製品分析', mobile_top: '技術なメリット', mobile_title: '食品飲料業界 / バイオ医薬品 / 疾病管理 / 研究機関・大学', mobile_bottom: '応用場面', 
                       cameraTitle: '盛泰独自開発の高精度コロニー検出・識別AIモデルを搭載し、1秒以内に高密度コロニーの迅速計数を実現（精度0.05mm）。盛泰カスタマイズの高画素マイクロレンズモジュールを採用し、サンプルの高精細画像保存を可能にします。',
                       list2Data: { list_1: '高密度・複雑な背景条件下でも正確かつ迅速なコロニー計数', list_2: 'すべてのデータ・画像・結果を自動保存、最小検出コロニーサイズ0.10mm'},
                       mobileList: [//技术优势图片
                            { id: 1, name: '技术优势1', url: require('@/assets/product_center/vehicle/菌落计数仪-技术优势1.png') },
                            { id: 2, name: '技术优势2', url: require('@/assets/product_center/vehicle/菌落计数仪-技术优势2.png') },
                                   ],  
                       imgLists: [  //应用场景图片
                            { id: 1, url: require('@/assets/product_center/vehicle/菌落计数仪-应用场景1.png') },
                            { id: 2, url: require('@/assets/product_center/vehicle/菌落计数仪-应用场景2.png') },
                    ],
                             mobileList_move: null,                               
                          },   
                         {
                              id: 6, isIMG: true, name: 'MCI-50微生物培養監視ワークステーション', liData: 'クアルコムQCS6490プラットフォーム搭載、ディープラーニングアルゴリズムを独立実行可能なスマート端末,盛泰独自開発の光学システムおよびコア特許取得済みのディープラーニングアルゴリズム。', 
                               cameraUrl_move: require('@/assets/product_center/vehicle/工作站图片日文.png'), 
                             isHeight: false, imgs: '', cameraUrl: require('@/assets/product_center/vehicle/工作站图片日文.png'), 
                               cameraName: '製品分析', mobile_top: '技術なメリット', mobile_title: '食品飲料業界 / バイオ医薬品 / 疾病管理 / 研究機関・大学', mobile_bottom: '応用場面', 
                                 cameraTitle: 'MCI-50は、盛泰が独自開発した光学検知システムを基盤とし、高精度なコロニー検出・識別AIモデルを搭載。多様な応用分野における複雑なシナリオ下でも、迅速かつ正確なコロニー計数を実現します。',
                                  list2Data: { list_1: 'クアルコムQCS6490プラットフォーム搭載、ディープラーニングアルゴリズムを独立実行可能なスマート端末。', list_2: '盛泰独自開発の光学システムおよびコア特許取得済みのディープラーニングアルゴリズム。'},
                                 mobileList: [//技术优势图片
                                  { id: 1, name: '技术优势1', url: require('@/assets/product_center/vehicle/工作站-技术优势1.png') },
                                 { id: 2, name: '技术优势2', url: require('@/assets/product_center/vehicle/工作站-技术优势2.png') },
                                  ],
                              imgLists: [  //应用场景图片
                                            { id: 1, url: require('@/assets/product_center/vehicle/工作站-应用场景1.png') },
                                            { id: 2, url: require('@/assets/product_center/vehicle/工作站-应用场景2.png') },
                                          ],
                                          mobileList_move: null,      
                    
                          },
                )
                isRefreshBool.value = true
                state.loading = false
                noMore.value = true
              }, Date)
            }
          }
         methods.alternate()
        }
      },
      // 控制左右布局的代码
      alternate(){
      let elCard = document.getElementsByClassName('elCard')
      let box_top = document.getElementsByClassName('box_top')
      let box_top_1 = document.getElementsByClassName('box_top_1')
      let box_bottom = document.getElementsByClassName('box_bottom')
      setTimeout(() => {
        for (let i = 0; i < box_top.length; i++) {
          if (i % 2 == 0) {
            box_top[i].style.marginLeft = '0rem'
            box_top_1[i].style.left = ''
             box_bottom[i].style.flexDirection = 'row'
          } else {
            box_top[i].style.marginLeft = '45rem'
            box_top_1[i].style.left = '-43rem'
            box_bottom[i].style.flexDirection = 'row-reverse'
          }
        }
      })
      // console.log(elCard);
      for (let i = 0; i < elCard.length; i++) {
        if (i % 2 == 0) {
          elCard[i].style.flexDirection = 'row'
        } else {
          elCard[i].style.flexDirection = 'row-reverse'
        }
      }
      }
    }

    onMounted(() => {
      methods.scrollSetData()
      // methods.tebBtn(localStorage.getItem('id'))
      methods.alternate()
      if (state.id == 5) {
        state.navData = state.UltraList
      } else if (state.id == 4) {
        state.navData = state.NBList
      } else if (state.id == 3) {
        state.navData = state.AIoTList
      } else if (state.id == 2) {
        state.navData = state.vehicleList
      } else if (state.id == 1) {
        state.navData = state.LEDList
      }


    });
    return {
      ...toRefs(state), ...methods, el_card, noMore, isRefreshBool
    };
  },
};
</script>

<style lang="less" scoped>
.elCard {
  overflow: hidden;
  width: 100%;
  height: 950px;
  display: flex;
  background-color: #F5F5F5;
  border-radius: 15px;

  .camera {
    flex: 2;
    padding: 120px 0px 30px 0px;

    .camera_img {
      margin-left: 30px;
      // width: 70%;
      // height: 74%;

      img {
        width: 100%;
        height: 66%;
      }
    }

    .camera_text {
      margin: 0 auto;
      width: 70%;
      height: 40%;
      padding: 0 30px 0 0;
      box-sizing: border-box;
      margin-bottom: 20px;

      h2 {
        margin-top: -10px;
        font-size: 24px;
        text-align: center;
      }

      p {
        Letter-spacing: 2px;
      }
    }
  }

  .imgList {
    flex: 4;
    padding: 40px 30px 40px 30px;
    display: flex;
    flex-direction: column;

    .imgList_top,
    .imgList_bottom {
      flex: 1;
    }

    .imgList_top {
      position: relative;

      li {
        margin-top: 20px;
        text-align: left;
        padding-left: 0 30px;
        box-sizing: border-box;
      }
    }

    .imgList_bottom {
      position: relative;
      box-sizing: border-box;
      padding-top: 18rem;


      .box_1 {
        position: relative;
        bottom: 60px;
        width: 100%;
        height: 100%;
        // background-color: red;
        display: flex;
        margin-top: -40px;

        .box_01 {
          flex: 1;
          text-align: center;
          
          img {
            width: 80%;
            margin-top: 30px;
            height: auto;

          }
        }

        .box_02 {
          text-align: center;
          margin: 0 auto;
          position: relative;

          img:nth-child(1) {
            width: 70%;
            margin-top: 30px;
            margin-left: 23px;
          }
        }

        .img_11 {
          width: auto;
          height: 300px;
          margin-left: 500px;
        }

        .img_22 {
          position: absolute;
          width: auto;
          height: 200px;
          left: -220px;
          top: 30px;
        }

        // .img_2 {
        //   flex: 1;
        //   padding: 35px;
        //   box-sizing: border-box;
        //   width: 30%;
        //   height: 65%;
        // }
      }
    }

    .imgList_bottom_1 {
      padding-top: 3rem;
    }
  }

}

.data_p {
  text-align: center;
  font-size: 24px;
  color: #666;
}

.el-tooltit {
  max-width: 400px;
  line-height: 180%;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {
  width: 100%;

  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
  }

  .igs {
    padding: 3.6rem !important;
    margin-top: -3.8rem !important;
  }

  .loading {
    font-size: 24px;
    text-align: center;
  }

  .content {
    margin: 0 auto;
    max-width: 1920px;
    width: 90%;

    .content_01 {
      width: 100%;
      height: 100px;
      line-height: 100px;
      // background-color: red;
      float: right;
      margin-right: 11%;

      .p1 {
        // margin-right: 30px;
        display: block;
        float: right;
        cursor: pointer;
        height: 65px;
        width: auto;
        margin-right: 20px;
        text-align: center;
        overflow: hidden;
      }

      .p2 {
        border-bottom: 3px solid #ef3e2e;
      }
    }

  }



  .contentData {
    overflow: auto;
    margin: 0 auto;
    max-width: 1920px;
    width: 70%;
    overflow-y: hidden
  }
}

// 走马灯
.demonstration {
  color: var(--el-text-color-secondary);
}


::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.imagess {
  width: 100%;
  height: auto;
  max-height: 600px
}

::v-deep .card {
  border-radius: 14px 14px 30px 30px;
  height: 180px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  margin-left: 4px;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }
}

.card_bottom_1 {
  margin-top: 110px;
}

.card_bottom {
  position: absolute;
  top: 5.12rem;
  left: 10px;
  z-index: 9;
  width: 100%;
  height: 150px;
  display: flex;
  display: flex;
  box-sizing: border-box;

  .box {
    flex: 1;

    p {
      text-align: left;
      text-align: center;
      width: 180px;
      overflow: hidden;
      height: 70px;
    }

    img {
      margin: 0 auto;
      width: 95%;
      padding: 10px;
      margin-top: -30px;
      box-sizing: border-box;
    }

  }
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .che {
      height: 14rem !important;
    }

    .content_top {
   padding: 30px;
      text-align: center;
      height: 200px;
      line-height: 150px;
      width: 80%;
      margin: 0 auto;
      white-space: nowrap;
      // overflow-y: hidden;
      // overflow-x: scroll;
      p {
        display: inline-block;
        font-size: 60px;
        margin: 0;
        margin-right: 50px;

        cursor: pointer;
      }

      .p1 {
        border-bottom: 8px solid #ef3e2e;
      }
    }

    .content_move {
      padding: 30px;
      width: 96.8%;


      .box_top {
        position: relative;
        font-size: 60px;
        color: #fff;
        width: 60%;
        text-align: center;
        height: 150px;
        line-height: 150px;
        background-color: #5C5C5C;
        position: relative;

        // margin-left: 1050px;
        .box_top_1 {
          position: absolute;
          left: 1110px;
          top: 70px;
          width: 700px;
          height: 10px;
          background-color: #5C5C5C;
        }
      }


      .box_bottom {
        display: flex;

        h2 {
          font-size: 50px;
          margin: 30px 0 20px 0;
        }

        p {
          width: 90%;
          margin: 0 auto;
          text-align: left;
          font-size: 40px
        }

        .img_left {
          flex: 4;
          text-align: center;

          img {
            width: 80%;
            margin-top: 250px;
          }
        }

        .img_right {
          flex: 6;
          text-align: center;

          img {

            margin: 20px 40px 0;
          }
        }


      }
    }
  }
}
</style>